.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.display-inline-block {
	display: inline-block;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}
