// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

//Layout Blueprints

@import "layout-blueprints";


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.quillContainer > .ql-container > .ql-editor{
    box-sizing: border-box;
    line-height: 1.42;
    height: 280px;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    border:1px solid #D3D3D3;
}

.reportQuillContainer > .ql-container > .ql-editor{
    box-sizing: border-box;
    line-height: 1.42;
    height: 160px;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    border:1px solid #D3D3D3;
}


.ql-view-container > .ql-container > .ql-editor{
    box-sizing: border-box;
    line-height: 1.42;
    height: 280px;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.ql-view-container > .ql-toolbar.ql-snow{
    display: none;
}

.ql-view-container > .ql-container.ql-snow.ql-disabled{
    border-top: 1px solid #ccc;
}

.textField-btn{
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: normal;
}

.templatename-field .MuiInputBase-input{
	  font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.4em;
    margin: 0;
    display: block;
    padding: 6px 6px 6px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.table-spacing.MuiTable-root{
  width: 100%;
  display: table;
  border-spacing: 30px;
  border-collapse: separate;
}

@media (max-width: 768px) {
	.w-100 {
		width: 100%;
	}
	.left-0 {
		left: 0%;
	}
}

@media (min-width: 769px) {
	.w-80 {
		width: 80%;
	}
	.left-10 {
		left: 10%;
	}
}

.filterHeader .MuiInputBase-fullWidth{
	width:9em;
}

.time .ant-table-filter-trigger{
	color: #1890ff;
}

.img-search {
	width: 150px;
	height: auto;
	cursor: pointer;
}

body .MuiChip-root {
  background-color: #0795fe;
  color: #fff;
}

.tp:hover{
  color: #0795fe,
}

.MuiButtonBase-root.btn-neutral-first {
	background: rgba(65, 145, 255, 0.15);
	color: #0795fe;
	border-color: transparent;
}
.MuiButtonBase-root.btn-neutral-first.active,
.MuiButtonBase-root.btn-neutral-first:hover {
	background: #0795fe;
	color: #fff;
}

.MuiPagination-root.pagination-first .MuiPaginationItem-root:hover {
	color: #0795fe;
	background: rgba(65, 145, 255, 0.05);
	border-color: #4191ff;
}

.MuiPagination-root.pagination-first .MuiPaginationItem-root.Mui-selected {
	background: #0795fe;
	color: #fff;
	border-color: #0795fe;
	box-shadow: 0 0.313rem 0.8rem rgba(65, 145, 255, 0.5), 0 0.126rem 0.225rem rgba(65, 145, 255, 0.3);
}

.MuiButtonBase-root.btn-neutral-dark-blue {
	background: #606065;
	color: #fff;
	border-color: transparent;
}
.MuiButtonBase-root.btn-neutral-dark-blue.active,
.MuiButtonBase-root.btn-neutral-dark-blue:hover {
	background: #096eb6;
	color: #fff;
}
.MuiButtonBase-root.btn-neutral-dark-blue.active .btn-wrapper--icon svg path,
.MuiButtonBase-root.btn-neutral-dark-blue:hover .btn-wrapper--icon svg path {
	fill: #fff;
}

.radioGroup{
  padding: 0.8px;
}

.text-info {
	color: #0795fe !important;
}

a.text-info:hover,
a.text-info:focus {
	color: #0795fe !important;
}

.display-5 {
	font-weight: 900;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary{
  color:#0795fe;
  border: 1px solid #0795fe;
}

.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-22.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-checked-23.Mui-checked.MuiIconButton-colorPrimary.Mui-checked{
  color:#0795fe;
}

.MuiSnackbar-anchorOriginBottomCenter.Snackbar-center{
  bottom: 50%;
  width: 35%;
  border: 3px solid #f4772e;
  border-radius: 0.3rem;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #0795fe;
}

.img-20{
  width: 20px;
	height: auto; 
	alt: icon;
	cursor: pointer;
}

.autoNote.img-20{
  width: 20px;
	height: auto; 
}

.tab-item-wrapper.select{
	opacity: 1;
	height: auto;
  overflow: hidden;
	visibility: visible;
}

.table-hover tbody tr:focus{
	color: #3b3e66;
	background-color: #e2e4ee;
}

.table-hover tbody tr:hover{
	color: #3b3e66;
	background-color: #e2e4ee;
}

a.talent:hover{
  color:#0795fe;
}

a.department:hover{
  color:#0795fe;
}

.report{
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  width: 40%;
}

.bottom-0{
  bottom: 0;
}

.pos-absolute{
  position: absolute;
}

.left-0{
  left: 0;
}

.left-bottom{
  margin-top: 110px;
  margin-left: 10px;
}

.w-40 {
	width: 40% !important;
}

.MuiFormControl-marginDense.project-edit-search{
  margin-top: 0px;
  margin-bottom: 0px;
}

.MuiOutlinedInput-inputMarginDense.project-edit-search{
  padding-top: 8px;
  padding-bottom: 8px;
}

.flowGrow{
  flex-grow: 1;
}

.notification.card-box{
  border-radius: 0.75rem 0.75rem 0rem 0rem;
}
.notification.table_row_report{
  border-left: 10px solid #0795fe;
}
.notification.table_row_task_execution{
  border-left:10px solid #00aeef;
}
.notification.table_row_task_match{
  border-left:10px solid #07e4fe;
}
.notification.table_row_o2o{
  border-left:10px solid #5161b3;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: red; /*#0795fe*/
  border-radius: 50%;
}

.shadow-xxl-down{
  box-shadow: 0 0.18rem 0rem rgba(166, 166, 185, 0.5), 0 0.326rem 3rem rgba(122, 123, 151, 0.3) !important;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.MuiList-root.nav-line.d-flex.nav-tabs-primary.pb-0 .MuiListItem-root.MuiListItem-button {
  margin: 0px;
  border-radius: 0px;
}

.card-header.pr-2.bw-1{
  border-width: 0px;
}

.btn.action{
  background-color: #0795fe;
  color: #FFFFFF;
}
.btn.action:hover{
  background-color: #0795fe;
}

.center {
  margin: auto;
}

.center.title{
  margin-left: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.MuiCardHeader-action{
  flex:0 0 auto;
  align-self: flex-start;
  margin-right: -8px;
  margin-top: auto;
  height: 80px;
}

.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock{
    font-weight: bold !important;
}

.MuiAutocomplete-inputRoot.MuiAutocomplete-input.talent-detail{
  width:0
}

.MuiAlert-standardSuccess{
  border:1px solid #0795fe;
  background-color: transparent;
}